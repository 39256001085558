@import 'scss/global.scss';

.page-404 {
    min-height: 100vh;
    background: url(/assets/images/bg404.png) no-repeat center center fixed;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    &__logo {
        color: $color--light;
        font-size: 180px;
        line-height: 240px;
        @include for-under-lg {
            font-size: 120px;
            line-height: 140px;
        }
        @include for-under-md {
            font-size: 100px;
            line-height: 120px;
        }
        @include for-xs {
            font-size: 90px;
            line-height: 110px;
        }
        font-weight: 700;
        letter-spacing: -0.5px;
    }
    &__title {
        color: $color--light;
        font-size: 48px;
        line-height: 64px;
        font-weight: 700;
        @include for-under-lg {
            font-size: 40px;
            line-height: 56px;
        }
        @include for-under-md {
            font-size: 36px;
            line-height: 52px;
        }
        @include for-xs {
            font-size: 28px;
            line-height: 42px;
        }
        letter-spacing: 0.48px;
    }
    &__btn {
        color: $color--light;
        @include fontBold;
        @include font-20;
        letter-spacing: 0.2px;
        text-decoration: none;
        padding: 16px 48px;
        background-color: #155df5;
        border: none;
        border-radius: 8px;
        &:hover {
            background-color: #0e4abf;
        }
    }
}
